const userGridData = [
    {
        id: 1,
        name: 'David McHenry',
        designation: 'Agent de police',
        status: "En service",
        service: "Alternance",
        email: 'david@skote.com'
    },
    {
        id: 2,
        image: require('@/assets/images/users/avatar.jpg'),
        name: 'Frank Kirk',
        designation: 'Contrôleur',
        status: "Hors service",
        service: "De jours",
        email: 'frank@skote.com'
    },
    {
        id: 3,
        image: require('@/assets/images/users/avatar.jpg'),
        name: 'Rafael Morales',
        designation: 'Gendarme',
        status: "En service",
        service: "De nuit",
        email: 'Rafael@skote.com'
    },
    {
        id: 4,
        name: 'Mark Ellison',
        designation: 'Contrôleur',
        status: "En service",
        service: "De nuit",
        email: 'mark@skote.com'
    },
    {
        id: 5,
        image: require('@/assets/images/users/avatar.jpg'),
        name: 'Minnie Walter',
        designation: 'Agent de police',
        status: "Congés",
        service: "Alternance",
        email: 'minnie@skote.com'
    },
    {
        id: 6,
        image: require('@/assets/images/users/avatar.jpg'),
        name: 'Shirley Smith',
        designation: 'Contrôleur',
        status: "Congés",
        service: "De nuit",
        email: 'shirley@skote.com'
    },
    {
        id: 7,
        name: 'John Santiago',
        designation: 'Gendarme',
        status: "Hors service",
        service: "De jours",
        email: 'john@skote.com'
    },
    {
        id: 8,
        image: require('@/assets/images/users/avatar.jpg'),
        name: 'Colin Melton',
        designation: 'Gendarme',
        status: "En service",
        service: "Alternance",
        email: 'colin@skote.com'
    },
];

export { userGridData };